import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import { getCourseNavigation } from "../../../../../store/courses"
import { useCourseStore } from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { dictAnswerIsValid } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })
  const task = {
    courseId: "reiseerinnerung",
    chapterId: "02-dichterwerkstatt",
    taskId: "strophen-und-verse",
  }

  const isValid =
    dictAnswerIsValid({ ...task, dictId: "verse1" }) &&
    dictAnswerIsValid({ ...task, dictId: "verse2" }) &&
    dictAnswerIsValid({ ...task, dictId: "verse3" }) &&
    dictAnswerIsValid({ ...task, dictId: "verse4" }) &&
    dictAnswerIsValid({ ...task, dictId: "verse5" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/reiseerinnerung/02-dichterwerkstatt/silben"
        />
      }
    >
      <Seo title="Strophen & Verse zählen" />
      <Box>
        <Stack>
          <FeedbackHeaderBool
            confirmed={isValid}
            title="Die ersten 4 Strophen zählen jeweils 12 Verse, die letzte nur 11."
            level={4}
          />
          <Paragraph>
            Hat Hölderlin, der seine Gedichte sonst so streng mathematisch
            anlegte, hier etwa geschlampt? Oder könnte der fehlende letzte Vers
            etwas mit der Aussage der letzten Strophe zu tun haben?
          </Paragraph>
          <Paragraph>
            Durch den fehlenden letzten Vers entsteht eine Leerstelle, die
            tatsächlich gut an das in der letzten Strophe aufgerufene Bild
            anschließt: Sie endet am Meeresufer, das zugleich »gibt und nimmt«,
            Erinnerungen anspült und wieder mit sich fortträgt. Auch der letzte
            Vers wurde scheinbar von den Wellen fortgetragen. Die damit
            entstandene Leerstelle kann jeder selbst mit eigenen Erinnerungen
            auffüllen.
          </Paragraph>
          <Paragraph>
            Das Ende der letzten Strophe ist aber auch ein Statement des Autors:
            »Was bleibet aber, stiften die Dichter«. Ein selbstbewusster Satz in
            einer Zeit, in der, verursacht durch die Französische Revolution,
            doch so vieles im Umbruch war. Die Aussage ist in die Zukunft
            gerichtet. Das Bleibende, das Werk der Dichter, muss erst noch
            geschaffen werden. Der fehlende 12. Vers könnte somit auch darauf
            hinweisen, dass noch etwas aussteht.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
